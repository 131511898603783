import * as React from 'react'
import { graphql, HeadProps } from 'gatsby'
import { PageTitle, PageContent, Layout, Grid, BlockRenderer, Table, HeadTags } from '@components'

interface PageProps {
  data: Queries.TermsAndConditionsQuery
}

const PrivacyPolicyPage = ({ data: { sanityPage } }: PageProps) => {
  if (!sanityPage) return <></>
  const { title, _rawCopy, tables } = sanityPage
  return (
    <Layout>
      <Grid>
        <PageTitle>{title}</PageTitle>
        <PageContent>
          <BlockRenderer>{_rawCopy}</BlockRenderer>
        </PageContent>
        {tables?.map(table => !!table && <Table key={table.slug?.current} {...table} />)}
      </Grid>
    </Layout>
  )
}

export const Head = (props: HeadProps) => (
  <HeadTags {...props}>
    <link rel="canonical" href="https://usepower.com/terms-and-conditions" />
  </HeadTags>
)

export const query = graphql`
  query TermsAndConditions {
    sanityPage(slug: { current: { eq: "terms-and-conditions" } }) {
      ...pageFields
      tables {
        slug {
          current
        }
        header
        labelPosition
        table {
          rows {
            cells
          }
        }
      }
    }
  }
`

export default PrivacyPolicyPage
